import React, {useState, useEffect} from "react";

import ApexColumnChart from "./apex-column-chart/apex-column-chart";
import ApexLineChart from "./apex-line-chart/apex-line-chart";
import ApexPieChart from "./apex-pie-chart/apex-pie-chart";
import ApexAreaChart from "./apex-area-chart/apex-area-chart";
import { useNavigate } from "react-router-dom";
import { getFormatDate } from "service/format-date";
import { SelectDropdown } from "commons/input-controls";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import { StandartButton } from "commons/form-controls";
import DropdownController from "items/dropdown/dropdown-controller";
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import useComponentVisible from "hooks/useComponentVisible";
import fontText from "assets/fonts/font-text";
import s from "./apex-chart.module.scss"

const ApexChart = (props) => {

    var formats = ['jpg', 'png', 'pdf', 'svg']

    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    const getFields = (fields, index, label, type) => {
        if(fields.language){
            let iter = null
            let lang = fields.language
            lang.forEach((k, i) => {
                if(k !== 'ru' && k !== 'en' && k !== 'kz'){
                    if(!iter) iter = i
                    lang[iter] = "OTHERS"
                }
                else
                lang[i] = k
            })
            fields.language = lang
        }
        let ret = fields[type[label]] ? fields[type[label]][index] : null//label === 'series' && key.length ? fields[key[0]][index] : fields[key[1]] ? fields[key[1]][index] : null;
        return ret
    }

    const clickOnElement = (event, chartContext, config) => {

        if(config && config.seriesIndex !== -1){
          let dataPointIndex = config.seriesIndex
          let seriesIndex = config.dataPointIndex
    
          let seriesSelected = props.fields ? getFields(props.fields, seriesIndex, 'seriesIndex', props.fieldsType) : null;
          let dataPointSelected = props.fields && props.fieldsType && props.fieldsType.dataPointIndex ? getFields(props.fields, dataPointIndex, 'dataPointIndex', props.fieldsType) : null;
          let seriesNext = props.fields ? getFields(props.fields, seriesIndex+1, 'seriesIndex', props.fieldsType) : null;
          
          goToPage(seriesSelected, dataPointSelected, seriesNext)
        }
      }

    const getFormatedDate = (date) => {

        if(props.grouping === 'HOURLY')
            return getFormatDate(date, 'HH:mm')
        else
            return getFormatDate(date, 'DD-MM-YY')
    }

    const isDate = (item) => {
        let d = moment(item, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()
        return d
    }

    const getLabel = (items) => {
        let d = isDate(items[0]) ? items.map(k => getFormatedDate(k)) : items
        return d
    }

    const getGraphicType = (fields) => {
        let keys = Object.keys(fields);
        let ret = {label: keys[0], data: keys[1]}
        if(fields.topicId) ret.topicId = fields.topicId
        return ret;
    }

    const [graphicType, setGraphicType] = useState(props.fields ? getGraphicType(props.fields) : null);
    const [topicIDs, setTopicIDs] = useState(props.selectedUUId)
    const [labels, setLabels] = useState(getLabel([...props.data.labels]))
    const navigate = useNavigate();
    const [rerender, setRerender] = useState(false);
    const [showFormats, setShowFormats] = useState(false);

    useEffect(() => {
        setGraphicType(props.fields ? getGraphicType(props.fields) : null);
        setLabels(getLabel([...props.data.labels]));
        setTopicIDs(props.selectedUUId);
        setRerender(!rerender);
    }, [props, props.title]);

    const getLocationName = () => {
        let lastArr = window.location.pathname.split('/')
        return lastArr[lastArr.length -1]
    }

    /*const checkByType = (type, label) => {
        switch(type){
            case 'date':
                if(isDate(label)){
                    let d = moment(dataPoint, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD HH:mm:ss")
                    
                    let e = props.grouping === 'HOURLY' 
                                                        ? getFormatDate(moment(d, "YYYY-MM-DD HH:mm:ss").add(1, 'hours').format("YYYY-MM-DD HH:mm:ss"))  
                                                        : getFormatDate(moment(d, "YYYY-MM-DD HH:mm:ss").endOf('day').format("YYYY-MM-DD HH:mm:ss"))

                    d !== 'Invalid Date' && setdate(new Date(d))
                    e !== 'Invalid Date' && setEndDate(new Date(e))
                }
                break;
            default:
                return label
        }
    }*/

    const checkByGrouping = (grouping, date) => {
        let retDate = null

        switch(grouping){
            case 'HOURLY':
                retDate = moment(date).add(1, 'hours');
                break;
            case 'DAILY':
                retDate = moment(date).add(1, 'days');
                break;
            case 'WEEKLY':
                retDate = moment(date).add(1, 'weeks');
                break;
            case 'MONTHLY':
                retDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ").add(1, 'months').subtract(1, 'hours');
                
                break;

            
        }
        let res = getFormatDate(retDate.format("YYYY-MM-DD HH:mm:ssZ"), "YYYY-MM-DD HH:mm:ss")
        return res
    }

    const setdate = (start, end) => {
        let s = start ? new Date(start) : new Date()
        let e = end ? new Date(end) : new Date()

        s !== 'Invalid Date' && props.setStartDate(s)
        s !== 'Invalid Date' && props.setEndDate(e)
        props.setGroupingByPeriod(s, e)
    }

    const goToPage = (series, dataPoint, seriesNext) => {

        if(series){

            let labels = series
            let data = dataPoint
            let f = props.selectedMyFilter ? props.selectedMyFilter : {};
            let returnBackFilters = {...f}

            let sD = isDate(props.startDate) ? props.startDate : new Date();
            let eD = isDate(props.endDate) ? props.endDate : new Date();

            returnBackFilters.since = sD
            returnBackFilters.till = eD

            props.setReturnBackFilters({...returnBackFilters})
            
            props.setReturnBackPageName(getLocationName())

            props.setMenuSelected(props.selected)

            props.setReturnBackGrouping(props.grouping)

            props.setToNull(false)
            props.setSettingsMenuToNull(false)
            //setTimeout(() => {


                if(isDate(series)){
                    let d = moment(series, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
                    let e = isDate(seriesNext) ? moment(seriesNext, "YYYY-MM-DDTHH:mm:ssZ").subtract(1, 'seconds').format("YYYY-MM-DD HH:mm:ss") : checkByGrouping(props.grouping, series)//props.grouping === 'HOURLY' ? moment(d, "YYYY-MM-DD HH:mm:ss").add(1, 'hours').format("YYYY-MM-DD HH:mm:ss") : moment(d, "YYYY-MM-DD HH:mm:ss").endOf('day').format("YYYY-MM-DD HH:mm:ss")
                    setdate(d, e !== 'Invalid Date' ? e : null);
                    //e !== 'Invalid Date' ? setdate(e, 'endDate') : setdate(null, 'endDate')
                }
                if(isDate(dataPoint)){
                    let d = moment(dataPoint, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
                    let e = props.grouping === 'HOURLY' ? moment(d, "YYYY-MM-DD HH:mm:ss").add(1, 'hours').format("YYYY-MM-DD HH:mm:ss") : moment(d, "YYYY-MM-DD HH:mm:ss").endOf('day').format("YYYY-MM-DD HH:mm:ss")
                    setdate(d, e);
                    //e !== 'Invalid Date' && setdate(e, 'endDate')
                }

                if(props.fieldsType.seriesIndex !== 'date'){
                    f[props.fieldsType.seriesIndex] = [labels]
                    //labels = null
                }
                if(props.fieldsType.dataPointIndex && props.fieldsType.dataPointIndex && props.fieldsType.dataPointIndex){
                    f[props.fieldsType.dataPointIndex] = [data]
                }
                if(props.fieldsType.thirdIndex && props.fields[props.fieldsType.thirdIndex]){
                    f[props.fieldsType.thirdIndex] = props.fields[props.fieldsType.thirdIndex]
                }
                if(graphicType.topicId){
                    props.setTreeSelectedUUId(graphicType.topicId)
                }
                if(f[undefined]){
                    delete f[undefined]
                }
                navigate('/page/main', { replace: true })
                props.setMySelectedFilter(f)
                
                //setTimeout(()=>{
                    
                    //props.filtersChanged(true);
                    
                    //props.filtersChanged(true);
                    //props.setTreeSelectedUUId(props.selectedUUId)
                //},1500)
                

                
            //}, 500)
            
            
        }
    }

    const data = {labels: labels, values: props.data.values/*, origin: props.data.list ? props.data.list : props.data.values*/}

    const getCtx = (title, type, next = false) => {

        let el = document.getElementById(title)
        let ch = el.querySelector('[id*="apexchart"]');
        let chid = ch && ch.id ? ch.id.replace('apexcharts', '') : title;

        const chartInstance = new ApexCharts.getChartByID(chid);

        const cts = chartInstance ? chartInstance.ctx : null;

        if(cts){
            switch(type){
                case 'svg':
                    cts.exports.w.config.chart.toolbar.export.svg.filename = props.getValue(title)
                    cts.exports.exportToSVG(cts);
                    break;
                /*case 'png':
                    cts.exports.exportToPng()
                    break;*/
                case 'png':
                case 'jpg':
                case 'pdf':
                    var dataURL = cts.dataURI().then((uri) => {
      
                        const downloadLink = document.createElement("a");
                        downloadLink.href = uri.imgURI;
                        if(type == 'jpg' || type === 'png'){
                            downloadLink.download = `${props.getValue(title)}.${type}`;
              
                            document.body.appendChild(downloadLink);
                  
                            downloadLink.click();
                  
                            document.body.removeChild(downloadLink);
                        }
                        else{
                            var pdf = jsPDF();
                            pdf.setProperties({
                              title: props.getValue(props.title),
                            });
    
                            var header = []
                            let labels = isDate(props.data.labels[0]) ? props.data.labels.map( k => getFormatDate(k, "DD-MM-YYYY")) : [...props.data.labels]
    
                            let d = [];
    
                            props.data.values.forEach((k, i) => {
                                if(parseInt(k)){
                                   if(!d[i])
                                        d[i] = []
                                    d[i] = [labels[i], k]
                                    header=['', props.getValue('COUNT')]
                                }
                                else if(props.data.labels.length < props.data.values.length){
                                    if(!d[i])
                                        d[i] = []
                                    d[i][0] = k.name
                                    d[i] = d[i].concat(k.data)
                                    if(!header.length){
                                    header = labels
                                    header.unshift('')}
                                }
                                else{                             
                                    header[0] = '';
                                    header[i+1] = k.name
                                    k.data.forEach((s,j) => {
                                        if(!d[j])
                                            d[j] = []
                                        d[j][0] = labels[j]
                                        d[j][i+1] = k.data[j]
                                    })
                                }
                            })
                            var data = [d]
    
                            pdf.addImage(uri.imgURI, 'JPEG', 15, 10, 180, 50);
    
                            pdf.addFileToVFS("Rubik.ttf", fontText.font);
                            pdf.addFont("Rubik.ttf", "Rubik", "normal");
                            pdf.setFont("Rubik");
    
                            if(d[0].length < 16){
                                autoTable(pdf, {
                                    head: [header],
                                    body: d,
                                    styles: {
                                        font: 'Rubik',
                                        fontStyle: 'normal',
                                        fontSize: 7
                                    },
                                    columnStyles: {
                                        0: {cellWidth: 20},
                                    },
                                    startY: 70,
                                  })
                            }
    
    
                            pdf.save(props.getValue(props.title)+'.pdf')
                        }
                      });
                    break;
            }
        }
        else{

            !next ? getCtx(title, type, true) : formats = []
        }

          
          
          /*cts.exports.exportToCSV({
          series: cts.series,
          columnDelimiter: ','})*/
          setIsComponentVisible(false)

    }

    const getType = (type, data) => {
            switch(type){
                case 'column':
                    return <ApexColumnChart clickOnElement={clickOnElement} height={props.height} width={props.width} getValue={props.getValue} data={data} title={props.title} stacked={props.stacked} colors={props.colors} legend={props.legend} translate={'labels'} callback={goToPage}/>
                case 'line': 
                    return <ApexLineChart clickOnElement={clickOnElement} height={props.height} width={props.width} getValue={props.getValue} data={data} title={props.title} background={props.background} colors={props.colors}/>
                case 'Pie': 
                    return <ApexPieChart noData={props.noData} clickOnElement={clickOnElement} height={props.height} width={props.width} getValue={props.getValue} data={data} title={props.title} background={props.background} legend={props.legend} today={props.today} yesterday={props.yesterday} colors={props.colors} callback={goToPage}/>
                case 'area':
                    return <ApexAreaChart clickOnElement={clickOnElement} height={props.height} width={props.width} getValue={props.getValue} data={data} title={props.title} background={props.background} legend={props.legend} today={props.today} yesterday={props.yesterday} colors={props.colors} callback={goToPage}/>
                default:
                    return <ApexLineChart clickOnElement={clickOnElement} height={props.height} width={props.width} getValue={props.getValue} data={data} title={props.title} background={props.background}/>
            }
    }

    const showFormatsToggle = () => {
        setIsComponentVisible(!isComponentVisible)
    }

    const buttons = formats.map((k, i) => 
        <button key={i} onClick={getCtx.bind(this, props.title, k, false)}>{k}</button>
    )
                    
        
    

    return (
        <div>
            {props.title ? <div className={s.SelectBlock}>
                <StandartButton type="button" background={'White'} icon={DownloadIcon} callback={showFormatsToggle.bind(this, props.title)} />
                {isComponentVisible && <div className={s.ListOfFormats} ref={ref}>
                    {buttons}
                </div>}
            </div> : null}

            {getType(props.type, data)}
        </div>
    )

}

export default ApexChart;