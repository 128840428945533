import React from "react";
import MainPage from "./main";

import DropdownWithSearchContainer from "items/dropdown-with-search/dropdown-with-search-container";
import PeriodCalendarContainer from "items/period-calendar/period-calendar-container";
import SearchTextContainer from "items/search-text/search-text-container";
import SortFilterContainer from "items/sort-filter/sort-filter-container";
import FiltersContainer from "items/filters/filters-container";
import TreeContainer from "items/tree/tree-container";
import MyFiltersContainer from "items/myFilters/my-filters-container";
import { getFormatedDate } from "service/format-date";
import moment from 'moment';

class MainPageController extends React.Component{
    constructor(props){
        super(props);
        this.selectedTopics = [];
        this.searchList = {};
        this.selectedMyFilter = this.props.selectedMyFilter.topics && this.props.selectedMyFilter.topics !== undefined ? this.props.selectedMyFilter.topics : {}
        this.state = {
            selectedTopic: 0,
            selectedMyFilter: (this.props.selectedMyFilter.topics && this.props.selectedMyFilter.topics !== undefined) ? this.props.selectedMyFilter.topics.join(',') : '',
            selectedTopics: this.props.selectedTopicsIds && this.props.selectedTopicsIds.join(','),
            topics: this.props.selectedTopics && this.props.selectedTopics.join(','),
            page: this.props.type,
            update: false,
            isFirst: true
        }
    }

    setTopicsFilter = () => {
        if(this.props.topics){
            if(this.props.type !== 'Documents'){
                let topicsName = this.props.topics && this.props.topics.map( k => k.name);
                let topicsUUId = this.props.topics && this.props.topics.map( k => k.uuid);
                let topicsId = this.props.topics && this.props.topics.map( k => k.id);

                this.props.setMySelectedTopicsFilter({['topics']: topicsName}, 'topics');
                this.props.setSelectedTopics(topicsUUId,topicsId)
                this.props.filtersChanged(true)
                this.setState({
                    update: true
                })
            }        
            else{
                this.props.setMySelectedTopicsFilter({}, '');
                this.props.filtersChanged(true)
                this.setState({
                    update: true
                })
            }

        }

    }

    setSelected = () => {
        if(this.props.type !== 'Documents' && (!this.props.selectedMyFilter.topics || (this.props.selectedMyFilter.topics && !Object.keys(this.props.selectedMyFilter.topics).length)) && this.props.topicData && this.state.isFirst){
            let uuids = this.props.topicData.map(k => k.uuid)
            let ids = this.props.topicData.map(k => k.id)
             this.props.setSelectedTopics(uuids, ids)
             this.props.setMySelectedFilter({'topics': uuids}, 'ids')
             //this.props.setAddSelectedMyFilter
             this.setState({
                isFirst: false
             })
        }
        /*if(this.props.type !== 'Documents' && (!this.props.selectedMyFilter.topics || (this.props.selectedMyFilter.topics && !Object.keys(this.props.selectedMyFilter.topics).length)) && this.props.topicData && !this.state.isFirst){
            this.props.setSelectedTopics([this.props.topicData[0].uuid], [this.props.topicData[0].id])
            this.props.setMySelectedFilter({'topics': [this.props.topicData[0].uuid]}, 'ids')
        }*/
    }

    checkDatePeriodToOneDay = (startDate, endDate) => {
        let s = moment(startDate, "YYYY-MM-DDTHH:mm:ssZ")
        let e = moment(endDate, "YYYY-MM-DDTHH:mm:ssZ")
        if(e.add(1, 'days').isAfter(s)){
            let start = moment().subtract(6, 'days')
            start.startOf('day')
            this.props.setStartDate(new Date(start.format('YYYY-MM-DDTHH:mm:ssZ'))) 
            this.props.setEndDate(new Date(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')))
            this.props.setGrouping("DAILY")
            this.setState({
                grouping: "DAILY"
            })
        }
    }

    componentDidMount(){
        this.props.setShowLeftMenu(true);
        this.props.setShowRightMenu(true);
        //this.props.setGrouping("DAILY");
        if(this.props.role){
            this.props.getMyTopics(this.props.role);
        }
        else{
            this.props.getMe();
        }
        this.props.setThemes(this.props.topicData);
        if(!this.props.isReturnedBack && !this.props.returnedPageName){
            this.checkDatePeriodToOneDay(this.props.startDate, this.props.endDate);
        }
        else{
            this.props.setIsReturnedBack(false)
        }
        /*if(this.props.type !== 'Documents' && !this.props.selectedMyFilter.topics && this.props.topicData){
            this.props.setSelectedTopics([this.props.topicData[0].uuid], [this.props.topicData[0].id])
            this.props.setMySelectedFilter({'topics': [this.props.topicData[0].uuid]}, [this.props.topicData[0].id])
            //this.props.setAddSelectedMyFilter
        }*/
        if(this.props.role && this.props.role !== 'ROLE_MARKER')
            this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)
        else
            this.props.getMe();
        //this.props.setRightMenuComponent(<CalendarContainer footerButtons={'period'}/>, 'calendar');
        this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'period'}/>, 'periodCalendar');
        this.props.setRightMenuComponent(<SearchTextContainer />, 'searchText')
        this.props.setRightMenuComponent(<SortFilterContainer />, 'sorting')
        this.props.setShowPanel(true, true)
        //setTimeout(() => {this.setTopicsFilter()}, 300) 
        //this.props.getTopicDocuments("eb86f7b4-cf5d-429d-a587-47fbc05b113c", 10, 0)
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    setLeftMenu = (topicData, subtopics = null) => {
        
    }

    checkSubtopics = () => {
        if(this.props.topicData && this.props.topicData[this.props.selectedTopic].id !== this.props.subtopics[0].topicId ){

        }
    }

    getFiltersList = (startDate, endDate, topics, fields, subtopics, filters) => {
        switch(this.props.type){
            case 'BlackList':
                topics.length && this.props.getBlackListFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            case 'RecycleBin':
                topics.length && this.props.getDeletedFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;//getFavouriteManualFilters
            case 'FavouritePublications':
                topics.length && this.props.getFavouriteManualFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            case 'Favourite':
                topics.length && this.props.getFavouriteAutoFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            default:
                topics.length && this.props.getTopicsFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
        }  
    }

    componentDidUpdate(){
        if(this.props.role){
            if(this.props.type !== this.state.page){
                this.props.setSelectedMyFilterMenu('');
                this.props.clearSearchList();
            }
            if(this.searchList !== this.props.searchList){
                this.searchList = this.props.searchList
            }
            if(this.props.topicData && this.props.topicData.length){
                
                    if(this.props.role && this.props.role !== 'ROLE_MARKER'){
                        if(this.props.rightMenuComponent.findIndex( c => c.componentName === 'myFilters') === -1)
                            this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)
                    }
                    else
                        this.props.getMe();
                    if(this.state.page !== this.props.type){
                        //this.setTopicsFilter();
                        this.setState({
                            isFirst: true
                        })
                    }
                    if(this.props.selectedId && this.selectedTopics !== this.props.selectedTopic){
                        this.selectedTopics = this.props.selectedTopic;
                        this.props.getSubtopics(this.props.selectedId);
                        //this.props.clearSearchList();
                        this.searchList = [];
                        
                    }
                    if(this.props.type === 'Documents' || !this.props.type){
                        if(this.props.selectedTopics.length){
                            this.props.setSelectedTopics([],[])
                            this.props.setMySelectedFilter({topics: []}, 'topics');
                            this.setState({
                                topics: [],
                            })
                        } 
                        if(this.props.leftMenuComponent.findIndex( c => c.componentName === 'tree') === -1 || this.props.isSubtopicsUpdated){
                            this.props.setLeftMenuComponent(<TreeContainer items={this.props.topicData} subItems={this.props.subtopics}/>, 'tree', false, true)
                            this.props.setSubtopicsUpdated(false)
                            
                        }
                    }
                    else{
                        this.setSelected()
                        if(this.props.leftMenuComponent.findIndex( c => c.componentName === 'topics') === -1 || (this.props.selectedMyFilter.topics && (this.props.selectedMyFilter.topics.join(',') !== this.state.selectedMyFilter)) || this.props.isSubtopicsUpdated || (!this.props.selectedMyFilter.topics && this.state.selectedMyFilter !== "") || this.props.selectedTopics.join(',') !== this.state.topics || this.props.type !== this.state.page){
                            this.props.setSubtopicsUpdated(false)
                            let topics = []
                            let topicsId = [];
                            let topicsUUId = [];
                            if(this.props.selectedMyFilter.topics){
                                topics = this.props.selectedMyFilter.topics.map(k => this.props.topics.findIndex(t => t.uuid === k));
                                topicsId = topics.map(k => this.props.topics[k].id)
                                topicsUUId = topics.map(k => this.props.topics[k].uuid)
                            }
                            
                            this.props.setSelectedTopics(topicsUUId, topicsId)
                            
                            let topicsName = this.props.topics && this.props.topics.map( k => k.name);

                            this.setState({
                                selectedMyFilter: this.props.selectedTopics,
                                topics: this.props.selectedTopics,
                                page: this.props.type
                                
                            })
                            
                            this.props.setLeftMenuComponent(<DropdownWithSearchContainer selectedItems={topicsName} items={this.props.topics} setMySelectedFilter={this.props.setMySelectedFilter} selectedMyFilter={this.props.selectedMyFilter} indexKey={0} name={'topics'} setAddSelectedMyFilter={this.props.setAddSelectedMyFilter} setSelectedTopics={this.props.setSelectedTopics} selectedFilterTopics={this.props.selectedTopics}/>, 'topics', false, true)
                            this.setState({
                                selectedMyFilter: (this.props.selectedMyFilter.topics && this.props.selectedMyFilter.topics !== undefined) ? this.props.selectedMyFilter.topics.join(',') : '',
                                topics: this.props.selectedTopics && this.props.selectedTopics.join(','),
                                page: this.props.type
                                
                            })
                        }
                    }
                    if((this.props.rightMenuComponent.findIndex( c => c.componentName === 'filters') === -1) || this.props.selectedTopics.join(',') !== this.state.topics || this.props.type !== this.state.page){
                        this.selectedTopics = this.props.selectedTopics;
                        if(this.props.type === 'Documents' || !this.props.type || this.props.selectedTopics.length){
                            this.props.setRightMenuComponent(<FiltersContainer type={this.props.type} topics={(this.props.type !== 'Documents' || !this.props.type) ? this.props.selectedTopics : this.props.topicData[this.props.selectedTopic]} getFiltersList={this.getFiltersList}/>, 'filters');
                        }
                        else{
                            this.props.setRightMenuComponent(<div></div>, 'filters')
                        }
                        
                        this.setState({
                            page: this.props.type,
                            topics: this.props.selectedTopics && this.props.selectedTopics.join(','),
                            
                        })
                        this.props.resetCounter();
                        this.props.setPageName(this.props.type)
                    }
                    if(this.props.role !== "ROLE_MARKER"){
                        if(!this.props.tags && this.props.selectedPostItems && this.props.selectedPostItems.length >= 2){
                            this.props.getCompanyTags(this.props.companyId);
                        }
                    }
                //}

                
            }
            else
                if(!this.props.topicData) this.props.getMyTopics(this.props.role)
            /*if(this.props.rightMenuComponent.findIndex( c => c.componentName === 'calendar') === -1){
                this.props.setShowRightMenu(true);
                this.props.setRightMenuComponent(<CalendarContainer footerButtons={'period'}/>, 'calendar');
            }*/
        }
        else{
            this.props.getMe();
        }
    }

    componentWillUnmount(){
        //this.props.clearSearchList();
        this.props.setRemoveLeftMenuComponent();
        this.props.setRemoveRightMenuComponent();
        //this.props.setExtendedAnalyticComponent(null);
        this.props.setShowLeftMenu(false);
        this.props.setShowRightMenu(false);
        this.props.setShowPanel(false);
        //this.props.setSelectedMenu(0)
        this.props.setThemeTableData(null);//clear topic
        this.props.setSubtopics([]);//clear subtopic
        this.props.setSelectedTopics([],[]);
        //this.props.clearSearchList();
        this.props.setMySelectedFilter({topics: []}, 'topics');
    }

    render(){
        if(this.props.type === 'Documents' ? this.props.selectedUUId : this.selectedTopics.length){
            return <MainPage topicsSelected={this.selectedTopics} topicData={this.props.topicData} {...this.props} setLeftMenu={this.setLeftMenu}/>
        }
    }

}

export default MainPageController;